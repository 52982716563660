/* roboto-mono-200 - latin-ext_latin */
@font-face {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 200;
    src: local(""), url("../assets/fonts/roboto-mono-v13-latin-ext_latin-200.woff2") format("woff2"),
        /* Super Modern Browsers */
        url("../assets/fonts/roboto-mono-v13-latin-ext_latin-200.woff") format("woff"),
        /* Modern Browsers */
        url("../assets/fonts/roboto-mono-v13-latin-ext_latin-200.ttf") format("truetype"),
        /* Safari, Android, iOS */
        url("../assets/fonts/roboto-mono-v13-latin-ext_latin-200.svg#RobotoMono") format("svg");
    /* Legacy iOS */
}

/* roboto-mono-regular - latin-ext_latin */
@font-face {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("../assets/fonts/roboto-mono-v13-latin-ext_latin-regular.woff2") format("woff2"),
        /* Super Modern Browsers */
        url("../assets/fonts/roboto-mono-v13-latin-ext_latin-regular.woff") format("woff"),
        /* Modern Browsers */
        url("../assets/fonts/roboto-mono-v13-latin-ext_latin-regular.ttf") format("truetype"),
        /* Safari, Android, iOS */
        url("../assets/fonts/roboto-mono-v13-latin-ext_latin-regular.svg#RobotoMono") format("svg");
    /* Legacy iOS */
}

/* roboto-mono-600 - latin-ext_latin */
@font-face {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 600;
    src: local(""), url("../assets/fonts/roboto-mono-v13-latin-ext_latin-600.woff2") format("woff2"),
        /* Super Modern Browsers */
        url("../assets/fonts/roboto-mono-v13-latin-ext_latin-600.woff") format("woff"),
        /* Modern Browsers */
        url("../assets/fonts/roboto-mono-v13-latin-ext_latin-600.ttf") format("truetype"),
        /* Safari, Android, iOS */
        url("../assets/fonts/roboto-mono-v13-latin-ext_latin-600.svg#RobotoMono") format("svg");
    /* Legacy iOS */
}

*:not(.fa):not(.pi) {
    font-family: "Roboto", Fallback, sans-serif !important;
}

html {
    font-size: 14px;
    background-color: var(--surface-ground);

    body {
        margin: 0;
        overflow: hidden;
        min-height: calc(var(--actualVH, 1vh) * 100) !important;

        /* Column Priorities */
        @media only all {

            th.ui-p-6,
            td.ui-p-6,
            col.ui-p-6,
            th.ui-p-5,
            td.ui-p-5,
            col.ui-p-5,
            th.ui-p-4,
            td.ui-p-4,
            col.ui-p-4,
            th.ui-p-3,
            td.ui-p-3,
            col.ui-p-3,
            th.ui-p-2,
            td.ui-p-2,
            col.ui-p-2,
            th.ui-p-1,
            td.ui-p-1,
            col.ui-p-1 {
                display: none !important;
            }
        }

        /* Show priority 1 at 320px (22rem x 14px) */
        @media screen and (min-width: 22rem) {

            th.ui-p-1,
            td.ui-p-1 {
                display: table-cell !important;
            }

            col.ui-p-1 {
                display: table-column !important;
            }
        }

        /* Show priority 2 at 480px (34rem x 14px) */
        @media screen and (min-width: 34rem) {

            th.ui-p-2,
            td.ui-p-2 {
                display: table-cell !important;
            }

            col.ui-p-2 {
                display: table-column !important;
            }
        }

        /* Show priority 3 at 760px (54rem x 14px) */
        @media screen and (min-width: 54rem) {

            th.ui-p-3,
            td.ui-p-3 {
                display: table-cell !important;
            }

            col.ui-p-3 {
                display: table-column !important;
            }
        }

        /* Show priority 4 at 960px (61rem x 14px) */
        @media screen and (min-width: 61rem) {

            th.ui-p-4,
            td.ui-p-4 {
                display: table-cell !important;
            }

            col.ui-p-4 {
                display: table-column !important;
            }
        }

        /* Show priority 5 at 1000px (71rem x 14px) */
        @media screen and (min-width: 71rem) {

            th.ui-p-5,
            td.ui-p-5 {
                display: table-cell !important;
            }

            col.ui-p-5 {
                display: table-column !important;
            }
        }

        /* Show priority 6 at 1260px (90rem x 14px) */
        @media screen and (min-width: 80rem) {

            th.ui-p-6,
            td.ui-p-6 {
                display: table-cell !important;
            }

            col.ui-p-6 {
                display: table-column !important;
            }
        }

        .hunter-ws-status-ok {
            font-size: 1.5em;
            color: rgb(101, 224, 101);
        }

        .hunter-ws-status-nok {
            font-size: 1.5em;
            color: rgb(233, 40, 40);
        }

        .hunter-ws-status {
            position: -webkit-sticky;
            position: -moz-sticky;
            position: -ms-sticky;
            position: -o-sticky;
            position: sticky;
        }

        .hunter-ws-status-tr {
            top: 1%;
            margin-left: 98%;
        }

        .hunter-ws-status-tl {
            top: 1%;
            margin-left: 1%;
        }

        .hunter-ws-status-br {
            top: 95%;
            margin-left: 98%;
        }

        .hunter-ws-status-bl {
            top: 95%;
            margin-left: 1%;
        }

        .dashboard-top-docker>.p-dock {
            top: calc(var(--actualVH, 1vh) * 5) !important;
        }

        div.p-column-filter-overlay-menu {
            max-height: calc(var(--actualVH, 1vh) * 70);
            overflow: visible !important;

            // overflow-y: auto !important;
            .p-column-filter-constraints {
                overflow: visible !important;
            }

            .p-column-filter-constraint {
                overflow: visible !important;
            }
        }
    }
}